import React from "react";
import { TextAreaAntd } from "./styles";
import { TextAreaProps } from "antd/lib/input";

interface ITextAreaProps extends TextAreaProps {
  children?: React.ReactNode;
}

export const TextArea = (props: ITextAreaProps): JSX.Element => {
  return <TextAreaAntd {...props} />;
};

export default TextArea;
