import {
  CreateLeagueContainer,
  CreateLeagueWrapper,
  CreateLeagueText,
  CreateLeagueImage,
  CreateLeagueButton,
  HowToPlayWrapper,
  HowToPlayText,
  HowToPlayImage,
} from "./styles";

interface ICampaignProps {
  onNavigateToCreateLeague: () => void;
}

export const Campaign = (props: ICampaignProps): JSX.Element => {
  const { onNavigateToCreateLeague } = props;
  return (
    <CreateLeagueContainer>
      <CreateLeagueWrapper>
        <CreateLeagueImage
          src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Fbanner%2Fcampaign-banner.png?alt=media&token=270526fe-f73b-4f31-9e2b-74bfc0dac8d0"
          alt="create-league-banner"
        />
        <CreateLeagueButton onClick={onNavigateToCreateLeague}>
          <CreateLeagueText>สร้างลีค</CreateLeagueText>
        </CreateLeagueButton>
      </CreateLeagueWrapper>
      <HowToPlayWrapper>
        <HowToPlayImage src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Fbanner%2Fhow-to-play-banner.png?alt=media&token=959e4f88-4b05-4be3-bbfe-292b60c292c7" />
      </HowToPlayWrapper>
    </CreateLeagueContainer>
  );
};

export default Campaign;
