import styled from "styled-components";
import Button from "../../components/Button/Button";
import { Divider, Typography } from "antd";

const { Text } = Typography;

interface IBmiRateProps {
  bmiRate: string;
}

interface IBadgeProps {
  color: string;
}

interface ButtonFilterStyledProps {
  isActive: boolean;
}

export const Container = styled.div`
  height: 100%;
  padding-top: 4.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const Header = styled.div``;

export const Title = styled.div`
  text-align: center;
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
`;

export const WrapperFilter = styled.div`
  margin-top: 1.5rem;
`;

export const ButtonFilterStyled = styled(Button)<ButtonFilterStyledProps>`
  border-radius: 1.875rem;
  font-family: Sukhumvit Set;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  ${(props) =>
    props.isActive
      ? "&&& {background: rgba(0, 186, 229, 0.5);border: 1px solid #00bae5;color: #000000;}"
      : ""};
`;

export const Content = styled.div``;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  text-align: center;
  padding: 0.375rem 1rem 0rem 1rem;
`;

export const FooterButtonStyled = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: ${(props) => props.theme.activeButtonBackground};
  color: #ffffff;
  border: 1px solid #00bae5;
  font-family: Sukhumvit Set;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
`;

export const WrapperInfo = styled.div`
  margin-top: 1.5rem;
`;
export const Info = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 0.75rem 0.5rem;
`;

export const DividerStyled = styled(Divider)`
  background-color: #d4d9df;
`;

export const Badge = styled.div<IBadgeProps>`
  background-color: ${(props) =>
    props.color === "normal"
      ? "#5bd2a0"
      : props.color === "red"
      ? "#F44A3E"
      : "#00BAE5"};
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.15000000596046448px;
`;

export const BmiRateText = styled.div`
  text-transform: capitalize;
`;

export const WrapperContent = styled.div`
  padding-bottom: 5rem;
`;

export const WrapperBmiRate = styled.div`
  width: 100%;
`;
export const BmiRate = styled.div<IBmiRateProps>`
  width: 50%;
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  font-family: Sukhumvit Set;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
  margin: 2rem 0rem;
  text-transform: capitalize;
  ${(props: IBmiRateProps) =>
    props.bmiRate === "normal"
      ? "background-color:#C4EFDD;color: #0F8E59;"
      : props.bmiRate === "higher"
      ? "background-color:#FAA59E;color: #C1170B;"
      : "background-color:#C7E5FC;color: #1970B6;"}
`;

export const DividerMemberStyled = styled(Divider)`
  background-color: #d4d9df;
  margin: 0.5rem 0rem;
`;
