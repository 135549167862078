import React from "react";
import { Row, Col } from "antd";
import {
  CampaignInvitationContainer,
  Header,
  CreateLeagueContainer,
  Title,
  LeagueText,
  TextTitle,
  TextDescription,
  CampaignInvitationContent,
  LeagueContainer,
  LeagueDivider,
  CardLeague,
  ColUserGroup,
  UserGroupIcon,
} from "./styles";
import { IMyLeagueResponse } from "../../common/types/leagueTypes";

interface ICampaignInvitationProps {
  leagues: IMyLeagueResponse[];
  onNavigateToInvitationLeague: (id: string) => void;
  handleNavigateToCreateLeague: () => void;
  handleRedirectToLeagueInfo: (id: string) => void;
}

export const CampaignInvitation = (
  props: ICampaignInvitationProps
): JSX.Element => {
  const {
    leagues,
    onNavigateToInvitationLeague,
    handleNavigateToCreateLeague,
    handleRedirectToLeagueInfo,
  } = props;
  return (
    <CampaignInvitationContainer>
      <Header>
        <Title>สร้างลีคและชวนเพื่อน</Title>
      </Header>
      <CreateLeagueContainer onClick={handleNavigateToCreateLeague}>
        <Row align="middle">
          <Col>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Ficon%2Finvitation.png?alt=media&token=c57a7f6e-6862-44ef-8055-03209a57e0a0"
              alt="invitation-icon"
            />
          </Col>
          <Col>
            <div>
              <TextTitle>สร้างลีค</TextTitle>
            </div>
            <div>
              <TextDescription>
                สร้างลีคของคุณเพื่อแข่งขันกับเพื่อนของคุณ
              </TextDescription>
            </div>
          </Col>
        </Row>
      </CreateLeagueContainer>
      <CampaignInvitationContent>
        <LeagueContainer>
          <LeagueText>Your league ({leagues.length})</LeagueText>
        </LeagueContainer>
        {leagues.map((league: IMyLeagueResponse, index: number) => (
          <React.Fragment key={index}>
            <CardLeague justify="space-between" align="middle">
              <Col
                span={20}
                onClick={() => handleRedirectToLeagueInfo(league.id)}
              >
                <div>
                  <TextTitle>{league.name}</TextTitle>
                </div>
                <div>
                  <TextDescription>{league.description}</TextDescription>
                </div>
              </Col>
              <ColUserGroup span={4}>
                <UserGroupIcon
                  src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Ficon%2Fsocial.png?alt=media&token=aa212350-16e9-4efc-bd26-341705bb6968"
                  onClick={() => onNavigateToInvitationLeague(league.id)}
                />
              </ColUserGroup>
            </CardLeague>
            <LeagueDivider />
          </React.Fragment>
        ))}
      </CampaignInvitationContent>
    </CampaignInvitationContainer>
  );
};

export default CampaignInvitation;
