import styled from "styled-components";
import { Typography } from "antd";
import Button from "../../components/Button/Button";
import "../../common/font/font.css";

const { Text } = Typography;

export const CreateLeagueContainer = styled.div``;

export const CreateLeagueWrapper = styled.div`
  position: relative;
`;

export const CreateLeagueButton = styled(Button)`
  border: 1px solid #14be77;
  position: absolute;
  left: 1.5rem;
  bottom: 3rem;
  height: 33px;
  border-radius: 8px;
`;

export const CreateLeagueText = styled(Text)`
  color: ${(props) => props.theme.primaryText};
  font-family: "Circular Std";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.15000000596046448px;
`;

export const CreateLeagueImage = styled.img`
  width: 100%;
`;

export const HowToPlayWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 12.5rem;
`;

export const HowToPlayText = styled(Text)`
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
`;

export const HowToPlayImage = styled.img`
  width: 100%;
`;
