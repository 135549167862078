import { Footer, ButtonStyled } from "./styles";

interface IShareLeagueInfo {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  handleDownload: () => void;
}

export const ShareLeagueInfo = (props: IShareLeagueInfo): JSX.Element => {
  const { canvasRef, handleDownload } = props;
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <canvas
        id="share-league"
        ref={canvasRef}
        height="300"
        width={window.innerWidth - 40}
        style={{ width: "100%" }}
      />
      <Footer>
        <ButtonStyled type="primary" onClick={handleDownload}>
          Download
        </ButtonStyled>
      </Footer>
    </div>
  );
};

export default ShareLeagueInfo;
