import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { Theme } from "./theme";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./state/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <React.Fragment>
        <Suspense>
          <App />
        </Suspense>
      </React.Fragment>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
