export const getCommonHeader = (): Headers => {
  const headers = new Headers();
  const token = localStorage.getItem("access_token");

  headers.append("Content-Type", "application/json");
  headers.append("Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  return headers;
};

export const getBaseUrl = (): string => {
  return (
    process.env.REACT_APP_API_URL ||
    "https://oma.consumer-hc-nonprod.everapp.io/api"
  );
};
