import styled from "styled-components";
import { Typography, Row, Col, Divider, Carousel } from "antd";
import Button from "../../components/Button/Button";

const { Text } = Typography;

interface IBadgeProps {
  color: string;
}

interface ILeagueColProps {
  index: number;
}
interface ITitleBmiProps {
  background: string;
  color: string;
}
interface IBmiCardProps {
  index: number;
  length: number;
  background: string;
}

export const LeagueContainer = styled.div`
  width: 100%;
`;

export const LeagueWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const LeagueHeader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 1rem 1.75rem 1.25rem 1.75rem;
  top: 0;
`;

export const LeagueTitle = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeagueName = styled(Text)`
  font-family: "Sukhumvit Set";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
`;

export const LeagueTable = styled.div`
  background-color: white;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  .ant-carousel {
    width: 100%;
  }
`;

export const LeagueCol = styled(Col)<ILeagueColProps>`
  border-right: ${(props) =>
    props.index % 2 === 0 ? "0.5px solid #5BD2A0" : "none"};
  border-bottom: ${(props) =>
    [6, 7].includes(props.index) ? "none" : "0.5px solid #5BD2A0"};
  padding: 8px 10px;
`;
export const LeagueDivider = styled(Divider)`
  background-color: #d4d9df;
`;

export const LeagueButton = styled(Button)`
  border: 1px solid #14be77;
  position: absolute;
  left: 1.5rem;
  bottom: 3rem;
`;

export const LeagueText = styled(Text)`
  color: ${(props) => props.theme.primaryText};
`;

export const LeagueImage = styled.img`
  width: 100%;
  position: relative;
  height: 299px;
`;

export const HowToPlayWrapper = styled.div`
  padding: 1.813rem 1.5rem;
`;

export const Badge = styled(Text)<IBadgeProps>`
  background-color: ${(props) =>
    props.color === "normal"
      ? "#5bd2a0"
      : props.color === "red"
      ? "#F44A3E"
      : "#00BAE5"};
  color: #fff;
  font-size: 12px;
`;

export const InvitationIcon = styled.span`
  position: absolute;
  right: 0;
  color: #fff;
`;

export const TitleBmiContainer = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`;

export const TitleBmi = styled(Text)<ITitleBmiProps>`
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  padding: 2px 6px;
  border-radius: 4px;
  font-family: "Sukhumvit Set";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

export const LeagueBmiContainer = styled(Row)`
  padding: 1.25rem;
`;

export const BmiCard = styled(Row)<IBmiCardProps>`
  ${(props) =>
    props.index === 0
      ? "border-top-left-radius:8px;border-top-right-radius:8px"
      : props.index === props.length - 1
      ? "border-bottom-left-radius:8px;border-bottom-right-radius:8px"
      : "none"};
  background-color: ${(props) =>
    props.index % 2 === 0 ? "#fff" : props.background};
  padding: 0.5rem;
  box-shadow: 0px 1px 3px 0px #1018281a;
`;

export const ViewAllMemberContainer = styled.div`
  text-align: right;
  margin-top: 1.438rem;
  margin-bottom: 1.438rem;
`;

export const ViewAllMemberText = styled(Text)`
  color: #fff;
  text-decoration: underline;
`;

export const MemberNameCol = styled(Col)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MemberRow = styled(Row)`
  display: flex !important;
`;

export const MemberOneRow = styled(Row)`
  display: flex !important;
  padding: 8px 12px;
`;

export const LeagueCarousel = styled(Carousel)`
  z-index: 999;
  .slick-dots li button {
    display: none;
  }
  .slick-dots-bottom {
    top: -48px;
  }
  .slick-dots li {
    cursor: pointer;
    height: 8px !important;
    width: 8px !important;
    background: #c2c9d1;
    border-radius: 50%;
  }
  .slick-dots li.slick-active {
    background: #00bae5;
  }
  .slick-list {
    z-index: 99;
  }
`;

export const BmiText = styled(Text)`
  font-family: Circular Std;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  overflow-wrap: normal;
`;

export const MemberNameText = styled(Text)`
  font-family: Circular Std;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`;
