import React from "react";
import { useNavigate } from "react-router-dom";
import Invitation from "../../presentations/invitation/invitation";

export const InvitationContainer = (): JSX.Element => {
  const navigate = useNavigate();

  const [referralCode, setReferralCode] = React.useState<string>("");

  const handleGetLeagueInfo = (): void => {
    navigate(`/invitation/leagues/${referralCode}`);
  };

  return (
    <Invitation
      referralCode={referralCode}
      setReferralCode={setReferralCode}
      handleGetLeagueInfo={handleGetLeagueInfo}
    />
  );
};

export default InvitationContainer;
