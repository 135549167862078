import styled from "styled-components";
import { Row, Col, Typography } from "antd";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

const { Text } = Typography;

interface IInvitationProps {
  isActive: boolean;
}

export const InvitationContainer = styled(Row)`
  flex-direction: column;
  margin-top: 2rem;
  min-height: 95vh;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const InvitationHeader = styled(Col)``;
export const InvitationText = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
`;
export const InvitationContent = styled(Col)``;

export const InvitationContentText = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: rgba(0, 0, 0, 0.55);
  text-transform: uppercase;
`;
export const InvitationInput = styled(Input)``;
export const InvitationFooter = styled(Col)`
  width: 100%;
`;

export const InvitationFooterButton = styled(Button)<IInvitationProps>`
  width: 100%;
  height: 3.125rem;
  border: none;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.activeButtonBackground
      : props.theme.inActiveButtonBackground};
`;
export const InvitationFooterButtonText = styled(Text)`
  color: #fff;
`;
