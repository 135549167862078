import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeagueInfoApi, joinLeagueApi } from "../../state/league";
import InvitationLeagueInfo from "../../presentations/invitationLeagueInfo/invitationLeagueInfo";
import {
  IGetLeagueInfoRequest,
  IJoinLeagueRequest,
  IMember,
} from "../../common/types/leagueTypes";
import { AppDispatch } from "../../state/store";
import { RootState } from "../../state/rootReducer";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { message } from "antd";

export const InvitationLeagueInfoContainer = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [isFinishInitialLoad, setIsFinishInitialLoad] =
    React.useState<boolean>(false);
  const [leagueOwner, setLeagueOwner] = React.useState<IMember | undefined>(
    undefined
  );
  const [isFirstLand, setIsFirstLand] = React.useState<Boolean>(false);

  const league = useSelector((state: RootState) => state.league);

  React.useEffect(() => {
    checkLeagueId();
  }, []);

  React.useEffect(() => {
    if (isFirstLand) {
      if (
        league.leagueInfo.isLoading === false &&
        league.leagueInfo.isSuccess === false &&
        league.leagueInfo.message !== ""
      ) {
        message.error(league.leagueInfo.message);
        navigate("/invitation");
      }
      if (league.leagueInfo.isSuccess) {
        findLeagueOwner();
        setIsFinishInitialLoad(true);
      }
    }
  }, [league.leagueInfo]);

  React.useEffect(() => {
    if (league.joinLeague.isSuccess) {
      navigate(`/leagues/${league.leagueInfo.league.id}`);
    }
    if (
      league.joinLeague.isLoading === false &&
      league.joinLeague.isSuccess === false &&
      league.joinLeague.message !== ""
    ) {
      message.error(league.joinLeague.message);
      navigate("/invitation");
    }
  }, [league.joinLeague]);

  const findLeagueOwner = () => {
    const owner = league.leagueInfo.league.members.find(
      (member: IMember) => member.id === league.leagueInfo.league.owner_id
    );
    setLeagueOwner(owner);
  };

  const checkLeagueId = () => {
    if (id !== undefined && id !== "") {
      const leagueInfoRequest: IGetLeagueInfoRequest = {
        referralCode: id,
      };
      dispatch(getLeagueInfoApi(leagueInfoRequest));
      setIsFirstLand(true);
    }
  };

  const handleJoinLeague = () => {
    if (id !== undefined && id !== "") {
      const request: IJoinLeagueRequest = {
        referralCode: id,
      };
      dispatch(joinLeagueApi(request));
    } else {
      message.error("Something went wrong");
      navigate("/invitation");
    }
  };

  return isFinishInitialLoad ? (
    <InvitationLeagueInfo
      leagueInfo={league.leagueInfo.league}
      handleJoinLeague={handleJoinLeague}
      leagueOwner={leagueOwner}
    />
  ) : (
    <Loading />
  );
};

export default InvitationLeagueInfoContainer;
