import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
  name: "NCD",
};

const routeSlice = createSlice({
  name: "route",
  initialState: initialStates,
  reducers: {
    CHANGE_MENU_NAME: (state, action) => (state.name = action.payload),
  },
});

export default routeSlice.reducer;
