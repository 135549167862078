import {
  InvitationContainer,
  InvitationHeader,
  InvitationText,
  InvitationContent,
  InvitationContentText,
  InvitationInput,
  InvitationFooter,
  InvitationFooterButton,
  InvitationFooterButtonText,
} from "./styles";

interface IInvitationProps {
  referralCode: string;
  setReferralCode: (referralCode: string) => void;
  handleGetLeagueInfo: () => void;
}

export const Invitation = (props: IInvitationProps): JSX.Element => {
  const { referralCode, setReferralCode, handleGetLeagueInfo } = props;
  return (
    <InvitationContainer justify="space-between" align="middle">
      <InvitationHeader>
        <InvitationText>กรอกโค้ดเพื่อเข้าร่วมลีค</InvitationText>
      </InvitationHeader>
      <InvitationContent>
        <InvitationContentText>Refer Code</InvitationContentText>
        <InvitationInput
          placeholder="Code"
          value={referralCode}
          onChange={(e): void => setReferralCode(e.target.value)}
        />
      </InvitationContent>
      <InvitationFooter>
        <InvitationFooterButton
          type="primary"
          isActive={referralCode !== ""}
          disabled={referralCode.trim().length === 0}
          onClick={handleGetLeagueInfo}
        >
          <InvitationFooterButtonText>ต่อไป</InvitationFooterButtonText>
        </InvitationFooterButton>
      </InvitationFooter>
    </InvitationContainer>
  );
};

export default Invitation;
