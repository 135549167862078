import { Route, Routes } from "react-router-dom";
import CampaignContainer from "../../containers/campaign/campaignContainer";
import CreateLeagueContainer from "../../containers/createLeague/createLeagueContainer";
import LeagueContainer from "../../containers/league/leagueContainer";
import CampaignInvitationContainer from "../../containers/campaignInvitation/campaignInvitationContainer";
import InvitationContainer from "../../containers/invitation/invitationContainer";
import InvitationLeagueInfoContainer from "../../containers/invitationLeagueInfo/invitationLeagueInfoContainer";
import CampaignInvitationLeagueInfoContainer from "../../containers/campaignInvitationLeagueInfo/campaignInvitationLeagueInfoContainer";
import NotFoundLeagueContainer from "../../containers/notFoundLeague/notFoundLeagueContainer";
import ShareLeagueInfoContainer from "../../containers/shareLeagueInfo/shareLeagueInfoContainer";
import ViewAllContainer from "../../containers/viewAll/viewAllContainer";

export const PublicRoutes = (): JSX.Element => (
  <Routes>
    <Route path="/campaign" element={<CampaignContainer />} />
    <Route path="/create-league" element={<CreateLeagueContainer />} />
    <Route path="/leagues" element={<LeagueContainer />} />
    <Route path="/leagues/:id" element={<LeagueContainer />} />
    <Route
      path="/campaign-invitation"
      element={<CampaignInvitationContainer />}
    />
    <Route
      path="/campaign-invitation/leagues/:id"
      element={<CampaignInvitationLeagueInfoContainer />}
    />
    <Route path="/invitation" element={<InvitationContainer />} />
    <Route
      path="/invitation/leagues/:id"
      element={<InvitationLeagueInfoContainer />}
    />
    <Route path="/not-found-league" element={<NotFoundLeagueContainer />} />
    <Route path="/share/leagues/:id" element={<ShareLeagueInfoContainer />} />
    <Route path="/view-all/leagues/:id" element={<ViewAllContainer />} />
  </Routes>
);

export default PublicRoutes;
