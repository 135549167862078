import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IGetLeagueInfoRequest, IMember } from "../../common/types/leagueTypes";
import Loading from "../../components/Loading/Loading";
import ViewAll from "../../presentations/viewAll/viewAll";
import { getLeagueInfoApi, getUserInfoApi } from "../../state/league";
import { RootState } from "../../state/rootReducer";
import { AppDispatch } from "../../state/store";

export const ViewAllContainer = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [filter, setFilter] = React.useState<string>("all");
  const [isFinishInitialLoad, setIsFinishInitialLoad] =
    React.useState<boolean>(false);
  const [higherBmiMembers, setHigherBmiMembers] = React.useState<IMember[]>([]);
  const [belowBmiMembers, setBelowBmiMembers] = React.useState<IMember[]>([]);
  const [normalBmiMembers, setNormalBmiMembers] = React.useState<IMember[]>([]);

  const leagueUserInfo = useSelector(
    (state: RootState) => state.league.userInfo
  );
  const leagueInfo = useSelector((state: RootState) => state.league.leagueInfo);

  React.useEffect(() => {
    fetchLeagueInfo();
    fetchUserInfo();
  }, []);

  React.useEffect(() => {
    if (leagueInfo.isSuccess) {
      initialBmiRate();
    }
  }, [leagueInfo]);

  React.useEffect(() => {
    if (leagueUserInfo.isSuccess && leagueInfo.isSuccess) {
      setIsFinishInitialLoad(true);
    }
  }, [leagueUserInfo, leagueInfo]);

  const fetchLeagueInfo = () => {
    if (id && id !== "") {
      const leagueInfoRequest: IGetLeagueInfoRequest = {
        referralCode: id,
      };
      dispatch(getLeagueInfoApi(leagueInfoRequest));
    }
  };

  const fetchUserInfo = () => {
    dispatch(getUserInfoApi());
  };

  const initialBmiRate = () => {
    const members = leagueInfo.league.members;
    const higherBmiRateMembers = members.filter(
      (member) => member.bmiRate === "higher"
    );
    const belowBmiRateMembers = members.filter(
      (member) => member.bmiRate === "below"
    );
    const normalBmiRateMembers = members.filter(
      (member) => member.bmiRate === "normal"
    );
    setHigherBmiMembers(higherBmiRateMembers);
    setBelowBmiMembers(belowBmiRateMembers);
    setNormalBmiMembers(normalBmiRateMembers);
  };

  const handleRedirectToLeagueInfo = () => {
    navigate(`/leagues/${id}`);
  };

  return isFinishInitialLoad ? (
    <ViewAll
      filter={filter}
      setFilter={setFilter}
      handleRedirectToLeagueInfo={handleRedirectToLeagueInfo}
      userInfo={leagueUserInfo.userInfo}
      higherBmiMembers={higherBmiMembers}
      belowBmiMembers={belowBmiMembers}
      normalBmiMembers={normalBmiMembers}
    />
  ) : (
    <Loading />
  );
};

export default ViewAllContainer;
