import styled from "styled-components";
import Button from "../../components/Button/Button";
import { Typography } from "antd";
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
const { Text } = Typography;

export const CreateLeagueContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  justify-content: space-between;
  height: 100vh;
`;

export const CreateLeagueHeader = styled.div`
  margin-top: 3.625rem;
  margin-bottom: 0.875rem;
  text-align: center;
`;

export const CreateLeagueContent = styled.div``;

export const CreateLeagueFooter = styled.div`
  width: 100%;
  padding: 1rem;
  .ant-btn[disabled] {
    background: ${(props) => props.theme.inActiveButtonBackground};
    border: 1px solid #e6e6e6;
  }
  .ant-btn {
    background: ${(props) => props.theme.activeButtonBackground};
    border: 1px solid #00bae5;
    border-radius: 8px;
    font-family: "Sukhumvit Set";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #ffffff;
  }
`;

export const CreateLeagueButton = styled(Button)`
  width: 100%;
  height: 3.125rem;
  border: none;
  border-radius: 8px;
`;

export const CreateLeagueText = styled(Text)`
  color: #fff;
`;

export const CreateLeagueHeaderText = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export const LeagueNameText = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.55);
`;
export const LeagueDescriptionText = styled(Text)`
  margin-top: 24px;
  font-family: Sukhumvit Set;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.55);
`;

export const CreateLeagueInput = styled(Input)`
  font-family: Sukhumvit Set;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 24px;
`;
export const CreateLeagueTextArea = styled(TextArea)`
  font-family: Sukhumvit Set;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;
