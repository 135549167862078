import React from "react";
import { InputAntd } from "./styles";
import { InputProps } from "antd/lib/input";

interface IInputProps extends InputProps {
  children?: React.ReactNode;
}

export const Input = (props: IInputProps): JSX.Element => {
  return <InputAntd {...props} />;
};

export default Input;
