import React from "react";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./components/routes/publicRoutes";
import Loading from "./components/Loading/Loading";

export const App = (): JSX.Element => {
  const [isFinishInitialLoad, setIsFinishInitialLoad] = React.useState(false);

  React.useEffect(() => {
    initialToken();
  }, []);

  const initialToken = () => {
    if (window.location.search !== "") {
      const accessToken = window.location.search.split("?access_token=")[1];
      localStorage.setItem("access_token", accessToken);
      setIsFinishInitialLoad(true);
    } else if (
      window.location.search === "" &&
      localStorage.getItem("access_token")
    ) {
      setIsFinishInitialLoad(true);
    }
  };
  return (
    <BrowserRouter>
      {isFinishInitialLoad ? <PublicRoutes /> : <Loading />}
    </BrowserRouter>
  );
};

export default App;
