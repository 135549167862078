import Campaign from "../../presentations/campaign/campaign";
import { useNavigate } from "react-router-dom";

export const CampaignContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const onNavigateToCreateLeague = () => {
    navigate("/create-league");
  };
  return <Campaign onNavigateToCreateLeague={onNavigateToCreateLeague} />;
};

export default CampaignContainer;
