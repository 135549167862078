import React from "react";
import { ILeagueInfo, IMember } from "../../common/types/leagueTypes";
import {
  LeagueContainer,
  LeagueImage,
  LeagueWrapper,
  LeagueHeader,
  LeagueTitle,
  LeagueName,
  LeagueTable,
  LeagueCol,
  LeagueDivider,
  Badge,
  InvitationIcon,
  ViewAllMemberContainer,
  ViewAllMemberText,
  TitleBmiContainer,
  TitleBmi,
  LeagueBmiContainer,
  BmiCard,
  MemberNameCol,
  MemberRow,
  LeagueCarousel,
  MemberOneRow,
  MemberNameText,
  BmiText,
} from "./styles";
import { UserAddOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { formatterFloat } from "../../common/format/format";

interface ILeagueProps {
  leagueInfo: ILeagueInfo;
  page: number;
  setPage: (page: number) => void;
  members: IMember[][];
  memberHighBmi: IMember[];
  memberLowBmi: IMember[];
  handleRedirectToCampaignAndInvitation: () => void;
  handleRedirectToViewAll: () => void;
}

export const League = (props: ILeagueProps): JSX.Element => {
  const {
    leagueInfo,
    members,
    memberHighBmi,
    memberLowBmi,
    handleRedirectToCampaignAndInvitation,
    handleRedirectToViewAll,
  } = props;
  return (
    <LeagueContainer>
      <LeagueWrapper>
        <LeagueImage
          alt="league-banner"
          src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Fbanner%2Fleague-banner.png?alt=media&token=e79763cd-7211-4342-8f45-b3f693429729"
        />
        <LeagueHeader>
          <LeagueTitle>
            <LeagueName>{leagueInfo.league.name}</LeagueName>
            <InvitationIcon>
              <UserAddOutlined
                onClick={handleRedirectToCampaignAndInvitation}
              />
            </InvitationIcon>
          </LeagueTitle>
          <ViewAllMemberContainer>
            <ViewAllMemberText onClick={handleRedirectToViewAll}>
              View All
            </ViewAllMemberText>
          </ViewAllMemberContainer>
          <LeagueTable>
            <LeagueCarousel>
              {members.map((member: IMember[], index: number) =>
                member.length === 1 ? (
                  <MemberOneRow>
                    <MemberNameCol span={12}>
                      <MemberNameText>{member[0].name}</MemberNameText>
                    </MemberNameCol>
                    <Col span={12}>
                      <MemberRow justify="space-around">
                        <Col span={4}>
                          <LeagueDivider type="vertical" />
                        </Col>
                        <Col span={9}>
                          <Badge color="normal">BMI</Badge>
                        </Col>
                        <Col span={11}>
                          <BmiText>{formatterFloat(member[0].bmi)}</BmiText>
                        </Col>
                      </MemberRow>
                    </Col>
                  </MemberOneRow>
                ) : (
                  <MemberRow key={index}>
                    {member.map((member: IMember, memberIndex: number) => (
                      <LeagueCol
                        span={12}
                        index={memberIndex}
                        key={memberIndex}
                      >
                        <MemberRow justify="space-between">
                          <MemberNameCol span={12}>
                            <MemberNameText>{member.name}</MemberNameText>
                          </MemberNameCol>
                          <Col span={12}>
                            <MemberRow justify="space-around">
                              <Col span={4}>
                                <LeagueDivider type="vertical" />
                              </Col>
                              <Col span={9}>
                                <Badge color="normal">BMI</Badge>
                              </Col>
                              <Col span={11}>
                                <BmiText>{formatterFloat(member.bmi)}</BmiText>
                              </Col>
                            </MemberRow>
                          </Col>
                        </MemberRow>
                      </LeagueCol>
                    ))}
                  </MemberRow>
                )
              )}
            </LeagueCarousel>
          </LeagueTable>
        </LeagueHeader>
        <LeagueBmiContainer gutter={6}>
          <Col span={12}>
            <TitleBmiContainer>
              <TitleBmi background="#FAA59E" color="#C1170B">
                ดันชีมวลกายสูงกว่าเกณฑ์
              </TitleBmi>
            </TitleBmiContainer>
            {memberHighBmi.map((member: IMember, index: number) => (
              <BmiCard
                key={index}
                index={index}
                length={memberHighBmi.length}
                background="#FCD2CF"
              >
                <Col span={12}>
                  <MemberNameText>{member.name}</MemberNameText>
                </Col>
                <Col span={12}>
                  <Row justify="space-around">
                    <Col span={4}>
                      <LeagueDivider type="vertical" />
                    </Col>
                    <Col span={9}>
                      <Badge color="red">BMI</Badge>
                    </Col>
                    <Col span={11}>{member.bmi}</Col>
                  </Row>
                </Col>
              </BmiCard>
            ))}
          </Col>
          <Col span={12}>
            <TitleBmiContainer>
              <TitleBmi background="#C7E5FC" color="#1970B6">
                ดันชีมวลกายต่ำกว่าเกณฑ์
              </TitleBmi>
            </TitleBmiContainer>
            {memberLowBmi.map((member: IMember, index: number) => (
              <BmiCard
                key={index}
                index={index}
                length={memberLowBmi.length}
                background="#E8F4FE"
              >
                <Col span={12}>
                  <MemberNameText>{member.name}</MemberNameText>
                </Col>
                <Col span={12}>
                  <Row justify="space-around">
                    <Col span={4}>
                      <LeagueDivider type="vertical" />
                    </Col>
                    <Col span={9}>
                      <Badge color="blue">BMI</Badge>
                    </Col>
                    <Col span={11}>{member.bmi}</Col>
                  </Row>
                </Col>
              </BmiCard>
            ))}
          </Col>
        </LeagueBmiContainer>
      </LeagueWrapper>
    </LeagueContainer>
  );
};

export default League;
