import React from "react";
import ShareLeagueInfo from "../../presentations/shareLeagueInfo/shareLeagueInfo";
import { useParams } from "react-router-dom";
import { useRef } from "react";

export const ShareLeagueInfoContainer = (): JSX.Element => {
  const { id } = useParams();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    initialCanvas();
  }, []);

  const initialCanvas = () => {
    const canvasObj = canvasRef.current;
    const image = new Image();
    image.src =
      "https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Fbanner%2Fshare-banner.png?alt=media&token=c56cefe6-615f-4ba4-b9c0-d488acbe9651";
    const ctx = canvasObj?.getContext("2d");
    if (ctx) {
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
        ctx.font = "24px Circular";
        ctx.fillStyle = "#14BE77";
        ctx.fillText(`${id}`, 100, 210);
      };
    }
    image.setAttribute("crossOrigin", "anonymous");
  };

  const handleDownload = () => {
    const canvas = document.getElementById("share-league") as HTMLCanvasElement;
    const image = canvas?.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = `share-league-${id}.png`;
    link.href = image;
    link.click();
  };

  return (
    <ShareLeagueInfo canvasRef={canvasRef} handleDownload={handleDownload} />
  );
};

export default ShareLeagueInfoContainer;
