import {
  CreateLeagueContainer,
  CreateLeagueHeader,
  CreateLeagueContent,
  CreateLeagueFooter,
  CreateLeagueButton,
  CreateLeagueText,
  CreateLeagueHeaderText,
  LeagueNameText,
  LeagueDescriptionText,
  CreateLeagueInput,
  CreateLeagueTextArea,
} from "./styles";

interface ICreateLeagueProps {
  onCreateLeague: () => void;
  leagueName: string;
  leagueDescription: string;
  setLeagueName: (value: string) => void;
  setLeagueDescription: (value: string) => void;
}

export const CreateLeague = (props: ICreateLeagueProps): JSX.Element => {
  const {
    onCreateLeague,
    leagueName,
    leagueDescription,
    setLeagueName,
    setLeagueDescription,
  } = props;
  return (
    <CreateLeagueContainer>
      <CreateLeagueContent>
        <CreateLeagueHeader>
          <CreateLeagueHeaderText>สร้างลีค</CreateLeagueHeaderText>
        </CreateLeagueHeader>
        <LeagueNameText>ชื่อลีค</LeagueNameText>
        <CreateLeagueInput
          placeholder="ชื่อ"
          onChange={(e) => setLeagueName(e.target.value)}
        />
        <LeagueDescriptionText>คำอธิบายลีค</LeagueDescriptionText>
        <CreateLeagueTextArea
          rows={4}
          placeholder="(แล้วแต่ระบุ)"
          onChange={(e) => setLeagueDescription(e.target.value)}
        />
      </CreateLeagueContent>
      <CreateLeagueFooter>
        <CreateLeagueButton
          disabled={leagueName === "" || leagueDescription === ""}
          onClick={onCreateLeague}
        >
          <CreateLeagueText>สร้างลีค</CreateLeagueText>
        </CreateLeagueButton>
      </CreateLeagueFooter>
    </CreateLeagueContainer>
  );
};

export default CreateLeague;
