interface ITheme {
  primaryText: String;
  inActiveButtonBackground: String;
  activeButtonBackground: String;
}

export const Theme: ITheme = {
  primaryText: "#14BE77",
  inActiveButtonBackground: "#E6E6E6",
  activeButtonBackground: "#00BAE5",
};
