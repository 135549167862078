import { Spin } from "antd";
import { Container } from "./styles";

const Loading = (): JSX.Element => (
  <Container>
    <Spin size="large" />
  </Container>
);

export default Loading;
