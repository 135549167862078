import React from "react";
import { Row, Col } from "antd";
import { IMember, IUserInfoResponse } from "../../common/types/leagueTypes";
import { ALL_FILTERS } from "./constant";
import {
  Container,
  Header,
  Content,
  Footer,
  Title,
  FooterButtonStyled,
  WrapperFilter,
  ButtonFilterStyled,
  WrapperInfo,
  Info,
  DividerStyled,
  Badge,
  WrapperContent,
  WrapperBmiRate,
  BmiRate,
  BmiRateText,
  DividerMemberStyled,
} from "./styles";

interface IViewAllProps {
  filter: string;
  setFilter: (filter: string) => void;
  handleRedirectToLeagueInfo: () => void;
  userInfo: IUserInfoResponse;
  higherBmiMembers: IMember[];
  belowBmiMembers: IMember[];
  normalBmiMembers: IMember[];
}

interface IFilter {
  label: string;
  value: string;
}

interface ICardInfoProps {
  name: string;
  bmiRate: string;
  bmi: string;
}

const CardInfo = (props: ICardInfoProps) => {
  const { name, bmiRate, bmi } = props;
  const bmiRateText =
    bmiRate === "higher"
      ? "overweight"
      : bmiRate === "below"
      ? "underweight"
      : "normal";
  const color =
    bmiRate === "higher" ? "red" : bmiRate === "below" ? "blue" : "normal";
  return (
    <Row gutter={1} justify="space-between">
      <Col>{name}</Col>
      <Col>
        <Row>
          <Col>
            <BmiRateText>{bmiRateText}</BmiRateText>
          </Col>
          <Col>
            <DividerStyled type="vertical" />
          </Col>
          <Col>
            <Badge color={color}>BMI</Badge>
          </Col>
          <Col>{bmi}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export const ViewAll = (props: IViewAllProps): JSX.Element => {
  const {
    filter,
    setFilter,
    handleRedirectToLeagueInfo,
    userInfo,
    higherBmiMembers,
    belowBmiMembers,
    normalBmiMembers,
  } = props;
  return (
    <Container>
      <Header>
        <Title>View All</Title>
        <WrapperFilter>
          <Row gutter={4}>
            {ALL_FILTERS.map((item: IFilter) => (
              <Col key={item.value}>
                <ButtonFilterStyled
                  isActive={item.value === filter}
                  onClick={() => setFilter(item.value)}
                >
                  {item.label}
                </ButtonFilterStyled>
              </Col>
            ))}
          </Row>
        </WrapperFilter>
        <WrapperInfo>
          <Info>
            <CardInfo
              name={userInfo.name}
              bmi={userInfo.bmi}
              bmiRate={userInfo.bmiRate}
            />
          </Info>
        </WrapperInfo>
        <DividerStyled />
      </Header>
      <Content>
        <WrapperContent>
          {normalBmiMembers.length > 0 &&
            (filter === "normal" || filter === "all") && (
              <WrapperBmiRate>
                {(filter === "normal" || filter === "all") && (
                  <React.Fragment>
                    <BmiRate bmiRate="normal">normal</BmiRate>
                    {normalBmiMembers.map(
                      (member: IMember, memberIndex: number) => (
                        <React.Fragment key={member.id}>
                          <CardInfo
                            name={member.name}
                            bmi={member.bmi}
                            bmiRate={member.bmiRate}
                          />
                          {normalBmiMembers.length - 1 !== memberIndex && (
                            <DividerMemberStyled />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                )}
              </WrapperBmiRate>
            )}
          {higherBmiMembers.length > 0 &&
            (filter === "higher" || filter === "all") && (
              <WrapperBmiRate>
                {(filter === "higher" || filter === "all") && (
                  <React.Fragment>
                    <BmiRate bmiRate="higher">overweight</BmiRate>
                    {higherBmiMembers.map(
                      (member: IMember, memberIndex: number) => (
                        <React.Fragment key={member.id}>
                          <CardInfo
                            name={member.name}
                            bmi={member.bmi}
                            bmiRate={member.bmiRate}
                          />
                          {higherBmiMembers.length - 1 !== memberIndex && (
                            <DividerMemberStyled />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                )}
              </WrapperBmiRate>
            )}
          {belowBmiMembers.length > 0 &&
            (filter === "below" || filter === "all") && (
              <WrapperBmiRate>
                {(filter === "below" || filter === "all") && (
                  <React.Fragment>
                    <BmiRate bmiRate="below">underweight</BmiRate>
                    {belowBmiMembers.map(
                      (member: IMember, memberIndex: number) => (
                        <React.Fragment key={member.id}>
                          <CardInfo
                            name={member.name}
                            bmi={member.bmi}
                            bmiRate={member.bmiRate}
                          />
                          {belowBmiMembers.length - 1 !== memberIndex && (
                            <DividerMemberStyled />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                )}
              </WrapperBmiRate>
            )}
        </WrapperContent>
      </Content>
      <Footer>
        <FooterButtonStyled onClick={handleRedirectToLeagueInfo}>
          Back
        </FooterButtonStyled>
      </Footer>
    </Container>
  );
};

export default ViewAll;
