import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import CampaignInvitation from "../../presentations/campaignInvitation/campaignInvitation";
import { getMyLeagueApi } from "../../state/league";
import { RootState } from "../../state/rootReducer";
import { AppDispatch } from "../../state/store";

export const CampaignInvitationContainer = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [isFinishInitialLoad, setIsFinishInitialLoad] =
    React.useState<boolean>(false);

  const myLeague = useSelector((state: RootState) => state.league.myLeague);

  React.useEffect(() => {
    dispatch(getMyLeagueApi());
  }, []);

  React.useEffect(() => {
    if (myLeague.isSuccess) {
      setIsFinishInitialLoad(true);
    }
  }, [myLeague]);

  const onNavigateToInvitationLeague = (id: string) => {
    navigate(`/campaign-invitation/leagues/${id}`);
  };

  const handleNavigateToCreateLeague = () => {
    navigate("/create-league");
  };

  const handleRedirectToLeagueInfo = (id: string) => {
    navigate(`/leagues/${id}`);
  };

  return isFinishInitialLoad ? (
    <CampaignInvitation
      leagues={myLeague.leagues}
      onNavigateToInvitationLeague={onNavigateToInvitationLeague}
      handleNavigateToCreateLeague={handleNavigateToCreateLeague}
      handleRedirectToLeagueInfo={handleRedirectToLeagueInfo}
    />
  ) : (
    <Loading />
  );
};

export default CampaignInvitationContainer;
