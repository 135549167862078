import styled from "styled-components";
import { Row, Col, Typography } from "antd";
import Button from "../../components/Button/Button";

const { Text } = Typography;

export const InvitationLeagueInfoContainer = styled(Row)`
  flex-direction: column;
  margin-top: 2rem;
  min-height: 95vh;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const InvitationLeagueBanner = styled.img``;

export const InvitationLeagueInfoHeader = styled(Col)``;

export const InvitationLeagueInfoContent = styled(Col)`
  position: relative;
`;

export const InvitationLeagueInfoFooter = styled(Col)`
  width: 100%;
  margin-bottom: 2.5rem;
  .ant-btn > span {
    font-family: "Sukhumvit Set";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid #00bae5;
    border-radius: 8px;
  }
`;

export const InvitationLeagueInfoFooterButton = styled(Button)`
  width: 100%;
  height: 3.125rem;
  border: none;
  background-color: ${(props) => props.theme.activeButtonBackground};
`;
export const InvitationLeagueInfoFooterButtonText = styled(Text)`
  color: #fff;
`;

export const InvitationLeagueInfoWrapper = styled(Row)`
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LeagueInfoText = styled(Text)`
  color: #fff;
  font-family: Sukhumvit Set;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
`;

export const LeagueNameText = styled(Text)`
  color: #fff;
  font-family: Circular Std;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.15000000596046448px;
`;
