import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../state/store";
import League from "../../presentations/league/league";
import { RootState } from "../../state/rootReducer";
import { getLeagueInfoApi } from "../../state/league";
import { IGetLeagueInfoRequest, IMember } from "../../common/types/leagueTypes";
import Loading from "../../components/Loading/Loading";

export const LeagueContainer = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [isFinishInitialLoad, setIsFinishInitialLoad] =
    React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [members, setMembers] = React.useState<IMember[][]>([]);
  const [memberHighBmi, setMemberHighBmi] = React.useState<IMember[]>([]);
  const [memberLowBmi, setMemberLowBmi] = React.useState<IMember[]>([]);

  const league = useSelector((state: RootState) => state.league);

  React.useEffect(() => {
    initialGetLeagueInfo();
  }, []);

  React.useEffect(() => {
    if (league.leagueInfo.isSuccess) {
      initialDataToCarousel();
    }
  }, [league]);

  const initialGetLeagueInfo = () => {
    if (id && id !== "") {
      const leagueInfoRequest: IGetLeagueInfoRequest = {
        referralCode: id,
      };
      dispatch(getLeagueInfoApi(leagueInfoRequest));
    }
  };

  const initialDataToCarousel = () => {
    const leagueMembers: IMember[] = league.leagueInfo.league.members;
    const filterNormalBmi: IMember[] = [];
    const filterHighBmi: IMember[] = [];
    const filterLowBmi: IMember[] = [];
    const array: IMember[][] = [];
    for (let i = 0; i < leagueMembers.length; i++) {
      if (leagueMembers[i].bmiRate === "normal") {
        filterNormalBmi.push(leagueMembers[i]);
      } else if (leagueMembers[i].bmiRate === "higher") {
        filterHighBmi.push(leagueMembers[i]);
      } else {
        filterLowBmi.push(leagueMembers[i]);
      }
    }
    for (let i = 0; i < filterNormalBmi.length / 8; i++) {
      const slice = filterNormalBmi.slice(i * 8, (i + 1) * 8);
      array.push(slice);
    }
    setMembers(array);
    setMemberHighBmi(filterHighBmi);
    setMemberLowBmi(filterLowBmi);
    setIsFinishInitialLoad(true);
  };

  const handleRedirectToCampaignAndInvitation = () => {
    navigate("/campaign-invitation");
  };

  const handleRedirectToViewAll = () => {
    if (id && id !== "") {
      navigate(`/view-all/leagues/${id}`);
    }
  };

  return isFinishInitialLoad ? (
    <League
      leagueInfo={league.leagueInfo}
      page={page}
      setPage={setPage}
      members={members}
      memberHighBmi={memberHighBmi}
      memberLowBmi={memberLowBmi}
      handleRedirectToCampaignAndInvitation={
        handleRedirectToCampaignAndInvitation
      }
      handleRedirectToViewAll={handleRedirectToViewAll}
    />
  ) : (
    <Loading />
  );
};

export default LeagueContainer;
