import {
  NotFoundLeagueContainer,
  Header,
  Title,
  NotNowButton,
  CreateLeagueButton,
  Content,
  NotNowButtonText,
  CreateLeagueButtonText,
} from "./styles";
import { Col } from "antd";

interface INotFoundLeagueProps {
  handleClose: () => void;
  handleCreateLeague: () => void;
}

export const NotFoundLeague = (props: INotFoundLeagueProps): JSX.Element => {
  const { handleClose, handleCreateLeague } = props;
  return (
    <NotFoundLeagueContainer>
      <Header>
        <div>
          <Title>คุณยังไม่มีลีค</Title>
        </div>
        <div>
          <Title>สร้างลีคแรกของคุณสิ</Title>
        </div>
      </Header>
      <Content gutter={24}>
        <Col span={12}>
          <NotNowButton onClick={handleClose}>
            <NotNowButtonText>ยังไม่ใช่ตอนนี้</NotNowButtonText>
          </NotNowButton>
        </Col>
        <Col span={12}>
          <CreateLeagueButton onClick={handleCreateLeague}>
            <CreateLeagueButtonText>สร้างลีค</CreateLeagueButtonText>
          </CreateLeagueButton>
        </Col>
      </Content>
    </NotFoundLeagueContainer>
  );
};

export default NotFoundLeague;
