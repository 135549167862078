import styled from "styled-components";
import { Typography, Button } from "antd";

const { Text } = Typography;

export const CampaignInvitationLeagueInfoContainer = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const Header = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const Title = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
`;

export const CampaignInvitationLeagueInfoContent = styled.div`
  margin-top: 1.875rem;
`;

export const TextStyled = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.55);
`;

export const Content = styled.div`
  margin-top: 1rem;
`;

export const CampaignInvitationLeagueInfoFooter = styled.div`
  position: absolute;
  bottom: 2rem;
  padding: 1.5rem;
  right: 0;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 3.125rem;
  border-radius: 8px;
`;
