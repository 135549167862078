import styled from "styled-components";
import { Typography, Row } from "antd";
import Button from "../../components/Button/Button";

const { Text } = Typography;

export const NotFoundLeagueContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled(Row)`
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
`;

export const Content = styled(Row)`
  width: 100%;
  padding: 2rem;
`;

export const NotNowButton = styled(Button)`
  width: 100%;
  height: 48px;
  border: 1px solid #00bae5;
`;

export const NotNowButtonText = styled(Text)`
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  color: #00bae5;
`;
export const CreateLeagueButton = styled(Button)`
  width: 100%;
  height: 48px;
  background: #00bae5;
  border: 1px solid #00bae5;
`;
export const CreateLeagueButtonText = styled(Text)`
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
`;
