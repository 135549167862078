import {
  CampaignInvitationLeagueInfoContainer,
  Header,
  Title,
  CampaignInvitationLeagueInfoContent,
  TextStyled,
  Content,
  CampaignInvitationLeagueInfoFooter,
  ButtonStyled,
} from "./styles";
import { CopyOutlined } from "@ant-design/icons";

import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import { IGetLeagueInfoResponse } from "../../common/types/leagueTypes";

interface ICampaignInvitationLeagueInfo {
  handleCopyReferCode: (id: string) => void;
  league: IGetLeagueInfoResponse;
  handleRedirectToShareLeague: (id: string) => void;
}

export const CampaignInvitationLeagueInfo = (
  props: ICampaignInvitationLeagueInfo
): JSX.Element => {
  const { handleCopyReferCode, league, handleRedirectToShareLeague } = props;
  return (
    <CampaignInvitationLeagueInfoContainer>
      <Header>
        <Title>Invite</Title>
      </Header>
      <CampaignInvitationLeagueInfoContent>
        <Content>
          <TextStyled>LEAGUE NAME</TextStyled>
          <Input placeholder="League Name" value={league.name} />
        </Content>
        <Content>
          <TextStyled>DESCRIPTION</TextStyled>
          <TextArea placeholder="Description" value={league.description} />
        </Content>
        <Content>
          <TextStyled>SEND A REFER CODE TO YOUR FRIENDS</TextStyled>
          <Input
            placeholder="Refer Code"
            value={league.id}
            suffix={
              <CopyOutlined onClick={() => handleCopyReferCode(league.id)} />
            }
            disabled
          />
        </Content>
      </CampaignInvitationLeagueInfoContent>
      <CampaignInvitationLeagueInfoFooter>
        <ButtonStyled
          type="primary"
          onClick={() => handleRedirectToShareLeague(league.id)}
        >
          SHARE
        </ButtonStyled>
      </CampaignInvitationLeagueInfoFooter>
    </CampaignInvitationLeagueInfoContainer>
  );
};

export default CampaignInvitationLeagueInfo;
