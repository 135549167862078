import { combineReducers } from "@reduxjs/toolkit";
import routeReducer from "./route";
import league from "./league";

export const rootReducer = combineReducers({
  routeReducer,
  league,
});

export type RootState = ReturnType<typeof rootReducer>;
