import styled from "styled-components";
import { Button } from "../../components/Button/Button";

export const Footer = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  padding: 0 1rem;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 3rem;
  font-size: 1.2rem;
  border-radius: 8px;
  font-weight: 500;
`;
