export const ALL_FILTERS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Normal",
    value: "normal",
  },
  {
    label: "Overweight",
    value: "higher",
  },
  {
    label: "Underweight",
    value: "below",
  },
];
