import React from "react";
import { ButtonAntd } from "./styles";
import { ButtonProps } from "antd/lib/button";

interface IButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

export const Button = (props: IButtonProps): JSX.Element => {
  return <ButtonAntd {...props} />;
};

export default Button;
