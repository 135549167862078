import { Col } from "antd";
import {
  IGetLeagueInfoResponse,
  IMember,
} from "../../common/types/leagueTypes";
import {
  InvitationLeagueInfoContainer,
  InvitationLeagueInfoHeader,
  InvitationLeagueInfoContent,
  InvitationLeagueInfoFooter,
  InvitationLeagueInfoFooterButton,
  InvitationLeagueInfoFooterButtonText,
  InvitationLeagueBanner,
  InvitationLeagueInfoWrapper,
  LeagueInfoText,
  LeagueNameText,
} from "./styles";

interface IInvitationLeagueInfoProps {
  leagueInfo: IGetLeagueInfoResponse;
  handleJoinLeague: () => void;
  leagueOwner: IMember | undefined;
}

export const InvitationLeagueInfo = (
  props: IInvitationLeagueInfoProps
): JSX.Element => {
  const { leagueInfo, handleJoinLeague, leagueOwner } = props;
  return (
    <InvitationLeagueInfoContainer justify="space-between" align="middle">
      <InvitationLeagueInfoHeader></InvitationLeagueInfoHeader>
      <InvitationLeagueInfoContent>
        <InvitationLeagueBanner
          alt="league-banner"
          src="https://firebasestorage.googleapis.com/v0/b/ever-oma-dev.appspot.com/o/ncd%2Fbanner%2Fleague-full-banner.png?alt=media&token=7d591199-a9fc-4d3d-afaf-b96383cf795d"
        />

        <InvitationLeagueInfoWrapper align="middle" gutter={[24, 24]}>
          <Col>
            <LeagueInfoText>{leagueOwner?.name} เชิญคุณเข้าร่วม</LeagueInfoText>
          </Col>
          <Col>
            <LeagueNameText>{leagueInfo.name}</LeagueNameText>
          </Col>
          <Col>
            <LeagueInfoText>
              เพื่อน {leagueInfo.members.length} คน
            </LeagueInfoText>
          </Col>
        </InvitationLeagueInfoWrapper>
      </InvitationLeagueInfoContent>
      <InvitationLeagueInfoFooter>
        <InvitationLeagueInfoFooterButton
          type="primary"
          onClick={handleJoinLeague}
        >
          <InvitationLeagueInfoFooterButtonText>
            เข้าร่วม
          </InvitationLeagueInfoFooterButtonText>
        </InvitationLeagueInfoFooterButton>
      </InvitationLeagueInfoFooter>
    </InvitationLeagueInfoContainer>
  );
};

export default InvitationLeagueInfo;
