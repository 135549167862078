import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../state/store";
import { ICreateLeagueRequest } from "../../common/types/leagueTypes";
import CreateLeague from "../../presentations/createLeague/createLeague";
import { createLeagueApi } from "../../state/league";
import { RootState } from "../../state/rootReducer";
import Loading from "../../components/Loading/Loading";

export const CreateLeagueContainer = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [leagueName, setLeagueName] = React.useState<string>("");
  const [leagueDescription, setLeagueDescription] = React.useState<string>("");

  const league = useSelector((state: RootState) => state.league);

  React.useEffect(() => {
    if (league.createLeague.isSuccess) {
      navigate(`/leagues/${league.createLeague.referalCode}`);
    }
  }, [league, navigate]);

  const onCreateLeague = () => {
    const request: ICreateLeagueRequest = {
      name: leagueName,
      description: leagueDescription,
    };
    dispatch(createLeagueApi(request));
  };

  return league.createLeague.isLoading ? (
    <Loading />
  ) : (
    <CreateLeague
      onCreateLeague={onCreateLeague}
      leagueName={leagueName}
      leagueDescription={leagueDescription}
      setLeagueName={setLeagueName}
      setLeagueDescription={setLeagueDescription}
    />
  );
};

export default CreateLeagueContainer;
