import styled from "styled-components";
import { Typography, Divider, Row, Col } from "antd";

const { Text } = Typography;

export const CampaignInvitationContainer = styled.div`
  padding: 1.5rem;
  height: 100%;
`;

export const Header = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-self: center;
`;
export const CreateLeagueContainer = styled.div`
  padding: 0.625rem;
  border: 0.1px solid #d4d9df;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
`;

export const Title = styled(Text)`
  font-family: Sukhumvit Set;
  font-size: 20px;
  font-weight: 700;
`;

export const TextTitle = styled(Text)`
  font-family: Circular Std;
  font-size: 14px;
  font-weight: 700;
`;
export const TextDescription = styled(Text)`
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
`;

export const LeagueContainer = styled.div`
  margin: 1.75rem 0rem;
`;

export const CampaignInvitationContent = styled.div``;

export const LeagueDivider = styled(Divider)`
  margin: 0.625rem 0rem;
`;

export const CardLeague = styled(Row)``;

export const UserGroupIcon = styled.img`
  color: rgba(0, 0, 0, 0.35);
  cursor: pointer;
`;

export const LeagueText = styled(Text)`
  font-family: Circular Std;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`;

export const ColUserGroup = styled(Col)`
  text-align: right;
`;
