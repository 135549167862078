import { useNavigate } from "react-router-dom";
import NotFoundLeague from "../../presentations/notFoundLeague/notFoundLeague";

export const NotFoundLeagueContainer = (): JSX.Element => {
  const navigate = useNavigate();

  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  const handleCreateLeague = () => {
    navigate("/create-league");
  };

  return (
    <NotFoundLeague
      handleClose={handleClose}
      handleCreateLeague={handleCreateLeague}
    />
  );
};

export default NotFoundLeagueContainer;
