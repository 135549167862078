import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IGetLeagueInfoRequest } from "../../common/types/leagueTypes";
import Loading from "../../components/Loading/Loading";
import { message } from "antd";
import CampaignInvitationLeagueInfo from "../../presentations/campaignInvitationLeagueInfo/campaignInvitationLeagueInfo";
import { getLeagueInfoApi } from "../../state/league";
import { RootState } from "../../state/rootReducer";
import { AppDispatch } from "../../state/store";

export const CampaignInvitationLeagueInfoContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();

  const league = useSelector((state: RootState) => state.league.leagueInfo);

  const [isFinishInitialLoad, setIsFinishInitialLoad] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    initialLeagueInfo();
  }, []);

  React.useEffect(() => {
    if (league.isSuccess) {
      setIsFinishInitialLoad(true);
    }
  }, [league]);

  const initialLeagueInfo = () => {
    if (id && id !== "") {
      const request: IGetLeagueInfoRequest = {
        referralCode: id,
      };
      dispatch(getLeagueInfoApi(request));
    } else {
      navigate("/campaign-invitation");
    }
  };

  const handleCopyReferCode = (id: string) => {
    message.info("Copy Refer Code Success");
    navigator.clipboard.writeText(id);
  };

  const handleRedirectToShareLeague = (id: string) => {
    navigate(`/share/leagues/${id}`);
  };

  return isFinishInitialLoad ? (
    <CampaignInvitationLeagueInfo
      handleCopyReferCode={handleCopyReferCode}
      league={league.league}
      handleRedirectToShareLeague={handleRedirectToShareLeague}
    />
  ) : (
    <Loading />
  );
};

export default CampaignInvitationLeagueInfoContainer;
