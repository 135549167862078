import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { getBaseUrl, getCommonHeader } from "../common/api";
import {
  ICreateLeagueRequest,
  ILeagueState,
  ILeagueInfo,
  ILeagueReferralCode,
  IGetLeagueInfoRequest,
  IGetLeagueInfoResponse,
  IMyLeague,
  IMyLeagueResponse,
  IJoinLeague,
  IJoinLeagueRequest,
  IUserInfo,
  IUserInfoResponse,
} from "../common/types/leagueTypes";
import { decodeToken } from "../common/decode/decode";

const initialState = {
  createLeague: {
    referalCode: "",
    isSuccess: false,
    isLoading: false,
    message: "",
  } as ILeagueReferralCode,
  leagueInfo: {} as ILeagueInfo,
  myLeague: {} as IMyLeague,
  joinLeague: {} as IJoinLeague,
  userInfo: {} as IUserInfo,
} as ILeagueState;

export const createLeagueApi = createAsyncThunk(
  "createLeagueApi",
  async (request: ICreateLeagueRequest): Promise<ILeagueReferralCode> => {
    const headers = getCommonHeader();

    const response = await fetch(`${getBaseUrl()}/FatCampaigns/leagues`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    });
    return (await response.json()) as ILeagueReferralCode;
  }
);

export const getLeagueInfoApi = createAsyncThunk(
  "getLeagueInfoApi",
  async (request: IGetLeagueInfoRequest): Promise<IGetLeagueInfoResponse> => {
    const { referralCode } = request;
    const headers = getCommonHeader();

    const response = await fetch(
      `${getBaseUrl()}/FatCampaigns/leagues/${referralCode}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const responseJson = await response.json();
    if (responseJson.error) {
      throw responseJson.error;
    }
    return responseJson as IGetLeagueInfoResponse;
  }
);

export const getMyLeagueApi = createAsyncThunk(
  "getMyLeagueApi",
  async (): Promise<IMyLeagueResponse[]> => {
    const headers = getCommonHeader();
    const { id }: any = decodeToken();

    const response = await fetch(
      `${getBaseUrl()}/FatCampaigns/users/${id}/leagues`,
      {
        method: "GET",
        headers: headers,
      }
    );
    return (await response.json()) as IMyLeagueResponse[];
  }
);
export const getUserInfoApi = createAsyncThunk(
  "getUserInfoApi",
  async (): Promise<IUserInfoResponse> => {
    const headers = getCommonHeader();
    const { id }: any = decodeToken();

    const response = await fetch(`${getBaseUrl()}/FatCampaigns/users/${id}`, {
      method: "GET",
      headers: headers,
    });
    return (await response.json()) as IUserInfoResponse;
  }
);

export const joinLeagueApi = createAsyncThunk(
  "joinLeagueApi",
  async (request: IJoinLeagueRequest, thunkAPI) => {
    try {
      const headers = getCommonHeader();
      const { referralCode } = request;

      const response = await fetch(
        `${getBaseUrl()}/FatCampaigns/leagues/${referralCode}/users`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const responseJson = await response.json();
      if (responseJson && responseJson.error) {
        throw responseJson.error;
      }
    } catch (error: any) {
      if (error.statusCode === 400 || error.statusCode === 500) {
        return thunkAPI.rejectWithValue(error);
      }
      if (error.statusCode === 204 || error.statusCode === 200) {
        return thunkAPI.fulfillWithValue({
          message: "Successfully joined league",
        });
      }
    }
  }
);

const leagueSlice = createSlice({
  name: "league",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createLeagueApi.pending, (state, action) => {
      state.createLeague.isLoading = true;
    });
    builder.addCase(createLeagueApi.fulfilled, (state, action) => {
      state.createLeague.isLoading = false;
      state.createLeague.referalCode = action.payload.referalCode;
      state.createLeague.isSuccess = true;
    });
    builder.addCase(createLeagueApi.rejected, (state, action) => {
      state.createLeague.isLoading = false;
      state.createLeague.isSuccess = false;
    });
    builder.addCase(getLeagueInfoApi.pending, (state, action) => {
      state.leagueInfo.isLoading = true;
      state.leagueInfo.isSuccess = false;
      state.leagueInfo.message = "";
    });
    builder.addCase(getLeagueInfoApi.fulfilled, (state, action) => {
      state.leagueInfo.isLoading = false;
      state.leagueInfo.league = action.payload;
      state.leagueInfo.isSuccess = true;
      state.leagueInfo.message = "";
    });
    builder.addCase(getLeagueInfoApi.rejected, (state, action) => {
      state.leagueInfo.isLoading = false;
      state.leagueInfo.message = "Something went wrong";
      state.leagueInfo.isSuccess = false;
    });
    builder.addCase(getMyLeagueApi.pending, (state, action) => {
      state.myLeague.isLoading = true;
    });
    builder.addCase(getMyLeagueApi.fulfilled, (state, action) => {
      state.myLeague.isLoading = false;
      state.myLeague.leagues = action.payload;
      state.myLeague.isSuccess = true;
    });
    builder.addCase(getMyLeagueApi.rejected, (state, action) => {
      state.myLeague.isLoading = false;
      state.myLeague.isSuccess = false;
    });
    builder.addCase(getUserInfoApi.pending, (state, action) => {
      state.userInfo.isLoading = true;
    });
    builder.addCase(getUserInfoApi.fulfilled, (state, action) => {
      state.userInfo.isLoading = false;
      state.userInfo.userInfo = action.payload;
      state.userInfo.isSuccess = true;
    });
    builder.addCase(getUserInfoApi.rejected, (state, action) => {
      state.userInfo.isLoading = false;
      state.userInfo.isSuccess = false;
    });
    builder.addCase(joinLeagueApi.pending, (state, action) => {
      state.joinLeague.isLoading = true;
      state.joinLeague.isSuccess = false;
      state.joinLeague.message = "";
    });
    builder.addCase(joinLeagueApi.fulfilled, (state, action) => {
      state.joinLeague.isLoading = false;
      state.joinLeague.isSuccess = true;
      state.joinLeague.message = "";
    });
    builder.addCase(joinLeagueApi.rejected, (state, action) => {
      state.joinLeague.isLoading = false;
      state.joinLeague.isSuccess = false;
      state.joinLeague.message = "Something went wrong";
    });
  },
});

export default leagueSlice.reducer;

export const leagueSelector = (state: RootState): ILeagueState => state.league;

export const {} = leagueSlice.actions;
